import React from 'react'
import Header from './components/Header'
import Body from './components/Body'
import Footer from './components/Footer'
function App() {
  return (
    <div>
      <Header></Header>
      <Body></Body>
      <Footer></Footer>
    </div>
  )
}

export default App
