import React, { useState, useEffect } from "react";
import { Image } from "antd";
import DropDown from "./DropDown";
import axios from "axios";
import Flash from "../assets/Flash.svg";

function Body() {
  const [selectedQuote, setSelectedQuote] = useState({
    id: 1,
    quote: "The will of man is his happiness.",
    author: "Friedrich Schiller",
    category: "happiness",
  });

  const [index, setIndex] = useState(0);
  const [quotes, setQuotes] = useState([]);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(true);

  const url = `https://quotes-pi-sand.vercel.app/api/v1/quotes`;

  const fetchData = () => {
    axios
      .get(url)
      .then((res) => {
        setQuotes(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function randomNumber(minimum, maximum) {
    return Math.round(Math.random() * (maximum - minimum) + minimum);
  }
  const onSubmitButton = () => {
    setIndex(randomNumber(0, quotes.length-1));
    setSelectedQuote({
      quote: "The will of man is his happiness.",
      author: "Friedrich Schiller",
      category: "happiness",
    });
  };

  return (
    <div className="flex max-w-5xl mx-auto flex-col items-center justify-center">
      <main className="flex flex-1 w-full flex-col items-center justify-center text-center px-4 mt-18 sm:mt-12">
        <Image
          src={Flash}
          className="sm:w-12 sm:h-12 w-8 h-8 mt-0 "
          width={32}
          height={32}
        ></Image>
        <h1 className="sm:text-6xl text-4xl max-w-[708px] font-bold text-slate-900 mt-8">
          {!quotes.length
            ? selectedQuote.quote
            : quotes[index].quote}
        </h1>
        <p className="text-slate-500 mt-8">
          - {!quotes.length
            ? selectedQuote.author
            : quotes[index].author}
        </p>
        
        <div className="max-w-xl w-full">
          <div className="block">
            <DropDown
              onSubmitButton={onSubmitButton}
            ></DropDown>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Body;
