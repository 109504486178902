import React, { useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Select, Button } from "antd";
import axios from "axios";

const Option = Select.Option;

function DropDown({ onSubmitButton }) {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(true);

  const url = "https://quotes-pi-sand.vercel.app/api/v1/category";
  const fetchData = () => {
    axios
      .get(url)
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Menu as="div" className="relative block text-left w-full mt-3">
      <div>
        <Button
          className="bg-black rounded-xl text-white font-medium px-4 py-2 sm:mt-10 mt-6 hover:bg-black/80 w-full"
          style={{
            backgroundColor: "black",
            color: "white",
            borderRadius: "10px",
            height: "45px",
            fontSize: "16px",
            fontWeight: 600,
          
          }}
          onClick={onSubmitButton}
        >
          Surprise Me
        </Button>
      </div>
    </Menu>
  );
}

export default DropDown;
