import React,{useState} from "react";
import { Image, Input, Button } from "antd";
import Logo from "../assets/Logo.svg";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";

import "./Header.css"
function Header() {
  
  const [email,setEmail]=useState("")
  const handleChange = (e) => {
    setEmail(e.target.value)
  }
  const onSubmitButton = () => {
    axios.post('https://quotes-pi-sand.vercel.app/api/v1/addemail', {
     "email":email
    })
    .then((response) => {
      console.log(response);
      setEmail("")
    }, (error) => {
      console.log(error);
      setEmail("")
    });
  }
  return (
    <header className="flex justify-between items-center w-full border-b-1 pb-5 sm:px-4 px-2 w-full sm:pt-2 pt-4   flex sm:flex-row flex-col justify-between items-center px-3 space-y-3 sm:mb-0 mb-3">
      <a href="https://jobs.productmindset.io/" className="flex space-x-3 " target="blank">
        {/* <Image
          alt="header text"
          src={Logo}
          className="sm:w-12 sm:h-12 w-8 h-8 mt-1"
          width={32}
          height={32}
        /> */}
        <h1 className="sm:text-2xl text-2xl font-bold ml-10 tracking-tight ">
        ❤️ Product Mindset
        </h1>
      </a>
      <div className="flex space-x-3"></div>
      <div className="flex space-x-3  sm:flex-row sm:mb-0 mb-3">
        <Input
          value={email}
          placeholder="Joey_Tribbiani@gmail.com"
          // prefix={
          //   <MailOutlined style={{ fontSize: "15px", marginRight: "4px" }} />
          // }
          style={{
            borderRadius: "4px",
          }}
          onChange={handleChange}
          classNames="input_box"
        />
        <Button
          className="bg-black hover:bg-black/80 "
          style={{
            backgroundColor: "black",
            color: "white",
            borderRadius: "10px",
            height: "37px",
            fontSize: "16px",
            fontWeight: 600,
          }}
          onClick={onSubmitButton}
        >
          Subscribe
        </Button>
      </div>
    </header>
  );
}

export default Header;
